import { DataPoint } from '../DataPoint/class';

export class Selector extends DataPoint {

  constructor(props) {
    super(props);

    Object.keys(props).forEach(key => {
      this[key] = props[key];
    });
  }

  // Loops

  getLoop(id) {
    return this.getLoops().find(loop => loop.id === id);
  }
  getLoops() {
    const project = this.getProject();
    return project.getComponentItems().filter(loop => (this.loopIds || []).includes(loop.id));
  }

  // Slices

  getSlice(id) {
    return this.getSlices().find(s => s.id === id);
  }
  getSlices() {
    const project = this.getProject();
    return project.getSlices().filter(slice => (this.sliceIds || []).includes(slice.id));
  }

}
