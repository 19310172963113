// import firebase from 'firebase';
// import localforage from 'localforage';
import store from '../redux/store';
import { createSelector } from 'reselect';
import uuid from 'uuid';
import _ from 'lodash/fp/object';
import debounce from 'lodash/debounce';

import { updateProjectState, getProjectState } from '../Project/state';
import { getProjectMetaData } from '../Project/store';
// import { updateProjectState } from '../Project/actions';
import { getComponent, getComponentByName, getComponentsArray } from '../Component/slice';
import { getComponentItem, getComponentItemByName, getComponentItemsArray } from '../ComponentItem/slice';
import { getStyleRule, getStyleRulesArray } from '../StyleRule/slice';
import { getAllDataPoints, getDataPoint, getDataPoints, getComponents, getDataPointHistory } from '../DataPoint/store';
import { createDataPoint, updateDataPoint, removeDataPoint, addDataPointsHistory } from '../DataPoint/actions';
import { DataPoint } from '../DataPoint/class';
import { Component } from '../Component/class';
import { ComponentItem } from '../ComponentItem/class';
import { Theme } from '../Theme/class';
import { Attribute } from '../Attribute/class';
import { EventHandler } from '../EventHandler/class';
import { Action } from '../Action/class';
import { Reducer } from '../Reducer/class';
import { Selector } from '../Selector/class';
import { Function as Fn } from '../Function/class';
import { ComponentProp } from '../ComponentProp/class';
import { Dependency } from '../Dependency/class';
import { StyleRule } from '../StyleRule/class';
// import Assets from '../Assets/class';

export default class Project {

  constructor(props) {
    Object.keys(props).forEach(key => {
      this[key] = props[key];
    });
  }

  init() {
    // store.dispatch(requestProject(this.id));
  }

  // update(data) {
  //   const db = firebase.firestore();
  //   const user = firebase.auth().currentUser;
  //   const pRef = db.collection('workspaces').doc(this.workspaceId).collection('projects').doc(this.id);
  //
  //   store.dispatch(updateProject(data));
  //
  //   this.batch({
  //     ref: pRef,
  //     method: 'update',
  //     data
  //   });
  // }

  getMetaData() {
    return getProjectMetaData(store.getState());
  }

  updateMetaData() {
    const projectSettings = this.getSettings();
    if (projectSettings.metaTitle) {
      document.title = projectSettings.metaTitle;
    }
    if (projectSettings.metaDescription) {
      document.querySelector('meta[name="description"]').setAttribute("content", projectSettings.metaDescription);
    }
    if (projectSettings.metaViewport) {
      document.querySelector('meta[name="viewport"]').setAttribute("content", projectSettings.metaViewport);
    }
    if (projectSettings.metaThemeColor) {
      document.querySelector('meta[name="theme-color"]').setAttribute("content", projectSettings.metaThemeColor);
    }
    if (projectSettings.metaCharset) {
      document.querySelector('meta[charset]').setAttribute("charset", projectSettings.metaCharset);
    }
  }

  inspectElement(options) {
    const params = options || {};
    params.projectId = this.id;
    return window.appAPI.inspectElement(params);
  }

  // delete() {
  //   const db = firebase.firestore();
  //   const user = firebase.auth().currentUser;
  //
  //   store.dispatch(replaceDataPoints([]));
  //
  //   return db.collection('workspaces').doc(this.workspaceId).collection('projects').doc(this.id).delete();
  // }

  // requestData(projectId) {
  //   const db = firebase.firestore();
  //   const user = firebase.auth().currentUser;
  //
  //   if (this.requestData.unsubscribe) {
  //     this.requestData.unsubscribe();
  //   }
  //
  //   this.requestAssets();
  //
  //   return db.collection('workspaces').doc(this.workspaceId).collection('projects').doc(this.id || projectId).get().then(doc => {
  //     if (doc.exists) {
  //       const projectData = {...doc.data(), id: doc.id};
  //
  //       // add project data to class
  //       Object.keys(projectData).forEach(key => {
  //         this[key] = projectData[key];
  //       });
  //
  //       store.dispatch(updateProject(projectData));
  //
  //       console.log(this.getState())
  //       this.setState({
  //         dependencyLoadingState: 'pending'
  //       });
  //
  //       firebase.auth().currentUser.getIdToken().then(token => {
  //         fetch(`${process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : ''}/dependencies/${this.workspaceId}/projects/${this.id || projectId}`, {
  //           method: 'GET',
  //           mode: 'cors',
  //           headers: {
  //             'Authorization': `Bearer ${token}`
  //           }
  //         }).then(response => response.json()).then(json => {
  //
  //           this.setCache({
  //             dependenciesData: json
  //           });
  //           this.setState({
  //             dependencyLoadingState: 'complete'
  //           });
  //         });
  //       });
  //
  //       this.requestData.unsubscribe = db.collection('workspaces').doc(this.workspaceId).collection('projects').doc(this.id || projectId).collection('datapoints').onSnapshot(async (snapshot) => {
  //
  //         const projectInitialised = this.getState().initialised;
  //
  //         if (!snapshot.metadata.hasPendingWrites) {
  //           // data has been changed externally on the server
  //           const dataPoints = snapshot.docs.map(doc => ({...doc.data(), firebaseId: doc.id}));
  //           if (dataPoints.length) {
  //             store.dispatch(replaceDataPoints(dataPoints));
  //           } else if (!projectInitialised) {
  //             // empty project => create new data points
  //             // this.createInitDataPoints(projectData);
  //           }
  //           if (this.stageFrame) {
  //             this.stageFrame.contentWindow.postMessage({
  //               projectData: this.getData()
  //             }, '*');
  //           }
  //         }
  //
  //         if (!projectInitialised) {
  //           this.setState({
  //             initialised: true
  //           });
  //         }
  //       });
  //     } else {
  //       throw 'Project does not exist';
  //     }
  //   }).catch(error => {
  //     throw error;
  //   });
  // }

  getState() {
    return getProjectState(store.getState());
  }

  setState(data) {
    store.dispatch(updateProjectState(data));
    // this.messageParent('stateChange', {
    //   projectState: this.getState()
    // });

    // if (window.parent) {
    //   window.parent.postMessage({
    //     type: 'stateChange',
    //     projectState: this.getState()
    //   }, '*');
    // }
  }

  setCache(data) {
    this.setState({
      _cache: {
        ...this.getCache(),
        ...data
      }
    })
  }

  getCache() {
    return this.getState()._cache || {};
  }

  getData() {
    const state = store.getState();
    const datapoints = getAllDataPoints(state);
    return datapoints;
  }

  consoleLog(data) {
    if (window.parent) {
      window.parent.postMessage({type: 'log', logObj: JSON.stringify(data)}, '*');
    }
  }

  updateLocalWrites() {
    const cache = this.getCache();
    this.setCache({
      localWrites: cache.localWrites + 1
    });
  }

  // createDataPoint(data, { preventLocal } = {}) {
  //   const state = this.getState();
  //   const db = firebase.firestore();
  //   const user = firebase.auth().currentUser;
  //   const dpsRef = db.collection('workspaces').doc(this.workspaceId).collection('projects').doc(this.id).collection('datapoints');
  //   const dpRef = data.firebaseId ? dpsRef.doc(data.firebaseId) : dpsRef.doc();
  //   data.id = dpRef.id;
  //   data.firebaseId  = dpRef.id;
  //
  //   // store in state
  //   if (!preventLocal) {
  //     // store.dispatch(createDataPoint(data));
  //     this.dispatch({ type: 'createDataPoint', options: {data} });
  //   }
  //
  //   if (!this.isStage) {
  //     // store in cloud
  //     // dpRef.set(data);
  //     this.batch({
  //       ref: dpRef,
  //       method: 'set',
  //       data
  //     });
  //   }
  //
  //   return new Promise((resolve, reject) => {
  //     resolve(data);
  //   });
  // }

  // createInitDataPoints(projectData, stylekit) {
  //   const that = this;
  //   const db = firebase.firestore();
  //   const user = firebase.auth().currentUser;
  //   const batch = db.batch();
  //   const baseLayerId = uuid.v4();
  //   const datapoints = [
  //     {
  //       id: 'stylekit',
  //       name: 'Jelly UI',
  //       dataPointType: 'stylekit',
  //       ...(stylekit || {})
  //     },
  //     {
  //       id: 'app',
  //       name: 'App',
  //       elementId: 'app',
  //       dataPointType: 'component',
  //       loop: [],
  //       enableLoop: false,
  //       defaultLayerId: baseLayerId,
  //       layers: {
  //         [baseLayerId]: {
  //           id: baseLayerId,
  //           name: 'Layer 1',
  //           components: []
  //         }
  //       }
  //     },
  //     {
  //       id: 'settings',
  //       dataPointType: 'settings',
  //       classNames: {},
  //       externalStyles: {
  //         [uuid.v4()]: {
  //           link: 'https://cdn.jsdelivr.net/npm/normalize.css@8.0.1/normalize.css'
  //         }
  //       }
  //     }
  //   ];
  //
  //   datapoints.forEach(dp => {
  //     const dpRef = db.collection('workspaces').doc(this.workspaceId).collection('projects').doc(this.id).collection('datapoints').doc();
  //
  //     dp.firebaseId = dpRef.id;
  //     batch.set(dpRef, dp);
  //   });
  //
  //   store.dispatch(replaceDataPoints(datapoints));
  //
  //   return batch.commit();
  // }

  // saveDataPoint(datapointId, data) {
  //
  //   if (this.isStage) {
  //     return null;
  //   };
  //   const state = this.getState();
  //   const db = firebase.firestore();
  //   const user = firebase.auth().currentUser;
  //   const datapointsRef = db.collection('workspaces').doc(this.workspaceId).collection('projects').doc(this.id).collection('datapoints');
  //
  //   if (!this.isStage) {
  //     // store in cloud
  //     if (datapointId) {
  //       return this.batch({
  //         ref: datapointsRef.doc(datapointId),
  //         method: 'update',
  //         data
  //       });
  //     } else {
  //       return this.batch({
  //         ref: datapointsRef,
  //         method: 'add',
  //         data
  //       });
  //     }
  //   }
  // }

  updateDataPoint(id, data) {
    // store.dispatch(updateDataPoint(id, data));
    this.dispatch({ type: 'updateDataPoint', options: {id, data} });
  }

  // removeDataPoint(datapointId, { preventLocal } = {}) {
  //   const state = this.getState();
  //   const db = firebase.firestore();
  //   const user = firebase.auth().currentUser;
  //   const ref = db.collection('workspaces').doc(this.workspaceId).collection('projects').doc(this.id).collection('datapoints').doc(datapointId);
  //
  //   // remove from redux
  //   if (!preventLocal) {
  //     this.dispatch({ type: 'removeDataPoint', options: {id: datapointId} });
  //     // store.dispatch(removeDataPoint(datapointId));
  //   }
  //
  //   if (!this.isStage) {
  //     // remove from cloud
  //     return this.batch({
  //       ref,
  //       method: 'delete'
  //     });
  //   }
  // }

  dispatch({ type, options}) {

    this.dispatch.batch = this.dispatch.batch || [];

    this.dispatch.batch.push({type, options});



    this.setHistoryPoint();

    switch (type) {
      case 'updateDataPoint': {
        const { id, data } = options;
        store.dispatch(updateDataPoint(id, data));
        break;
      }
      case 'removeDataPoint': {
        const { id } = options;
        store.dispatch(removeDataPoint(id));
        break;
      }
      case 'createDataPoint': {
        const { data } = options;
        store.dispatch(createDataPoint(data));
        break;
      }
      default:
        break;
    }

    // if (this.stageFrame) {
    //   this.stageFrame.contentWindow.postMessage({
    //     projectData: this.getData()
    //   }, '*');
    // }

  }

  setHistoryPoint = debounce(this._setHistoryPoint, 500, {leading: true, trailing: false})

  _setHistoryPoint() {
    store.dispatch(addDataPointsHistory(this.dispatch.batch));
    this.dispatch.batch = [];
  }

  getHistory() {
    return getDataPointHistory(store.getState());
  }

  undo() {
    this.setHistory({type: 'before'});
  }

  redo() {
    this.setHistory({type: 'after'});
  }

  propertiesToArray(obj) {
    const isObject = val => typeof val === 'object' && !Array.isArray(val);

    const addDelimiter = (a, b) => a ? `${a}.${b}` : b;

    const paths = (obj = {}, head = '') => {
      return Object.entries(obj || {}).reduce((product, [key, value]) => {
        let fullPath = addDelimiter(head, key)
        return isObject(value) ? product.concat(paths(value, fullPath)) : product.concat(fullPath)
      }, []);
    }

    return paths(obj);
  }

  convertToDatapoints(projectData) {
    const datapoints = [
      ...(projectData.components || []),
      ...(projectData.componentTemplates || []),
      ...(projectData.actions || []),
      ...(projectData.slices || []),
      ...(projectData.sliceProps || []),
      ...(projectData.attributes || []),
      ...(projectData.eventHandlers || []),
      ...(projectData.reducers || []),
      ...(projectData.selectors || []),
      ...(projectData.componentProps || []),
      ...(projectData.functions || []),
      projectData.stylekit,
      projectData.settings,
      ...(projectData.dependencies || [])
    ];
    return datapoints;
  }

  getDataPoint(filter) {
    const getProject = () => this;
    const datapoint = getDataPoint(store.getState(), filter);
    if (datapoint.dataPointType === 'componentItem') {
      return new ComponentItem({
        ...datapoint,
        getProject
      });
    } else if (datapoint.dataPointType === 'stylekit') {
      return new Theme({
        ...datapoint,
        getProject
      });
    } else {
      return new DataPoint({
        ...datapoint,
        getProject
      });
    }
  }

  // COMPONENTS
  ///////////////////////////////////////////////////////////////////////

  getComponent(id, {loopContext} = {}) {
    const state = store.getState();
    const component = getComponent(state, id) || {};
    let componentData;

    if (component.templateId) {
      const template = this.getDataPoint({ id: component.templateId });
      componentData = _.merge(template.toObj(), component, {layers: template.toObj().layers});
    } else {
      componentData = {loopContext, ...component};
    }

    componentData.getProject = () => this;

    return new Component(componentData);
  }

  getComponentByName(name, {loopContext} = {}) {
    const state = store.getState();
    const component = getComponentByName(state, name);
    let componentData;

    if (component.templateId) {
      const template = this.getDataPoint({ id: component.templateId });
      componentData = _.merge(template.toObj(), component, {layers: template.toObj().layers});
    } else {
      componentData = {loopContext, ...component};
    }

    componentData.getProject = () => this;

    return new Component(componentData);
  }

  getComponentsData() {
    const state = store.getState();
    return JSON.stringify(getComponents(state));
  }

  getComponents({loopContext} = {}) {

    const state = store.getState();
    return getComponentsArray(state).map(component => {
      let componentData;
      if (component.templateId) {
        const template = this.getDataPoint({ id: component.templateId });
        componentData = _.merge(template.toObj(), {loopContext, ...component}, {layers: template.toObj().layers});
      } else {
        componentData = {loopContext, ...component};
      }

      componentData.getProject = () => this;

      return new Component(componentData);
    });
  }

  getComponentItem(id, {loopContext} = {}) {
    const state = store.getState();
    const component = getComponentItem(state, id);
    let componentData;

    componentData = {loopContext, ...component};

    componentData.getProject = () => this;

    return new ComponentItem(componentData);
  }

  getComponentItemByName(name, {loopContext} = {}) {
    const state = store.getState();
    const component = getComponentItemByName(state, name);
    let componentData;

    if (component.templateId) {
      const template = this.getDataPoint({ id: component.templateId });
      componentData = _.merge(template.toObj(), component, {layers: template.toObj().layers});
    } else {
      componentData = {loopContext, ...component};
    }

    componentData.getProject = () => this;

    return new ComponentItem(componentData);
  }

  getComponentItems({loopContext} = {}) {

    const state = store.getState();
    return getComponentItemsArray(state).map(component => {
      const componentData = {loopContext, ...component};
      componentData.getProject = () => this;

      return new ComponentItem(componentData);
    });
  }

  getComponentsCSS() {
    const components = this.getComponentItems();

    this.cssCache = this.cssCache || {};

    const cssDiffCache = components.reduce((map, component) => {
      map[component.id] = {
        inheritedStyles: component.getInheritedStyles(),
        styles: component.styleRules
      };
      return map;
    }, {});

    const componentsCSS = components.reduce((css, component) => {
      const cachedCSS = this.cssCache[component.id];

      if (cachedCSS && JSON.stringify(cssDiffCache[component.id]) === JSON.stringify((this.cssDiffCache || {})[component.id])) {
        return css + cachedCSS;
      } else {
        const newCSS = component.getCSS();
        this.cssCache[component.id] = newCSS;
        return css + newCSS;
      }
    }, '');

    this.cssDiffCache = cssDiffCache;

    return componentsCSS;


  }

  setComponentParent({componentId, newParentId, index}) {
    const component = this.getComponentItem(componentId);
    component.setParent(newParentId, index);
  }

  componentNameIsValid(name) {
    return !!name && !this.getComponentItems().filter(c => c.name === name).length;
  }

  getAllComponentClasses() {
    return this.getComponentItems().reduce((classArray, component) => {
      const classProp = component.getAttributeByName('class');
      let classes = [];
      if (classProp) {
        classes = (classProp.value || '').split(' ').filter(c => !!c && !classArray.includes(c) && !c.includes('${'));
      }

      if (classes.length) {
        return classArray.concat(classes);
      } else {
        return classArray;
      }
    }, []);
  }

  getComponentTemplates() {
    return getDataPoints(store.getState(), {dataPointType: 'componentTemplate'});
  }

  getComponentTemplate(id) {
    return this.getDataPoint({ id });
  }

  getComponentTemplateAsComponent(id) {
    return new ComponentItem(this.getComponentTemplate(id));
  }

  // COMPONENT PROPS
  /////////////////////////////////////////////////////////////////////////

  getComponentProp(id) {
    return this.getComponentProps().find(item => item.id === id) || {};
  }

  getComponentProps() {
    const that = this;
    return getDataPoints(store.getState(), {
      dataPointType: 'componentProp'
    }).map(reducer => new ComponentProp({
      ...reducer,
      getProject() {
        return that;
      }
    })) || [];
  }

  // SETTINGS
  ///////////////////////////////////////////////////////////////////////

  getSettings() {
    return this.getDataPoint({id: 'settings'});
  }

  getSetting(name) {
    return Object.entries(this.getSettings()[name] || {}).map(([id, data]) => ({id, ...data}));
  }

  settingCreate(name, data) {
    const settings = this.getSettings();
    const id = uuid.v4();

    settings.update({
      [name]: {
        ...(settings[name] || {}),
        [id]: {
          ...data
        }
      }
    });
  }

  settingUpdate(name, id, data) {
    const settings = this.getSettings();

    settings.update({
      [name]: {
        ...(settings[name] || {}),
        [id]: {
          ...(settings[name] || {})[id],
          ...data
        }
      }
    });
  }

  settingDelete(name, id) {
    const settings = this.getSettings();
    const clone = JSON.parse(JSON.stringify(settings[name] || {}));

    delete clone[id];

    settings.update({
      [name]: clone
    });
  }

  getStyleVariables() {
    return this.getSetting('styleVariables');
  }
  getStyleVariable(id) {
    return (this.getSettings().styleVariables || {})[id];
  }
  createStyleVariable(data) {
    this.settingCreate('styleVariables', data);
  }
  updateStyleVariable(id, data) {
    this.settingUpdate('styleVariables', id, data);
  }
  deleteStyleVariable(id) {
    this.settingDelete('styleVariables', id);
  }
  getStyleVariablesCss() {
    return ':root {' + this.getStyleVariables().reduce((css, sv) =>  {
      return `${css} --${sv.name}: ${sv.value};\r`;
    }, '') + '}';
  }

  getExternalStyles() {
    return this.getSetting('externalStyles');
  }
  createExternalStyle(data) {
    this.settingCreate('externalStyles', data);
  }
  updateExternalStyle(id, data) {
    this.settingUpdate('externalStyles', id, data);
  }
  deleteExternalStyle(id) {
    this.settingDelete('externalStyles', id);
  }

  getExternalScripts() {
    return this.getSetting('externalScripts');
  }
  createExternalScript(data) {
    this.settingCreate('externalScripts', data);
  }
  updateExternalScript(id, data) {
    this.settingUpdate('externalScripts', id, data);
  }
  deleteExternalScript(id) {
    this.settingDelete('externalScripts', id);
  }

  // CLASSNAMES
  ///////////////////////////////////////////////////////////////////////

  getClassName(id) {
    const settings = this.getSettings();
    return settings.classNames[id];
  }

  getClassNames() {
    const settings = this.getSettings();
    return Object.keys(settings.classNames).map(key => settings.classNames[key]);
  }

  createClassName(name) {
    const settings = this.getSettings();
    const existingClassName = this.getClassNames().find(cn => cn.name === name);

    if (existingClassName) {
      return existingClassName;
    } else {
      const id = uuid.v4();
      const data = { id, name };

      settings.update({
        classNames: {
          ...settings.classNames,
          [id]: data
        }
      });

      return data;
    }

  }

  // STYLE RULES

  getStyleRule(id, {loopContext} = {}) {
    const state = store.getState();
    const item = getStyleRule(state, id);
    const data = {...item};
    data.getProject = () => this;
    return new StyleRule(data);
  }

  getStyleRules({loopContext} = {}) {
    const state = store.getState();
    return getStyleRulesArray(state).map(item => {
      const data = {...item};
      data.getProject = () => this;
      return new StyleRule(data);
    });
  }

  getCSS() {
    const styleRules = this.getStyleRules();

    this.cssCache = this.cssCache || {};

    const css = styleRules.reduce((css, sr) => {
      const cachedCSS = this.cssCache[sr.id];
      const trackedStyleRule = (this.styleRuleTracker || []).find(srt => srt.id === sr.id);
      if (cachedCSS && trackedStyleRule && JSON.stringify(sr.toObj()) === JSON.stringify(trackedStyleRule.toObj())) {
        return css + cachedCSS;
      } else {
        this.cssCache[sr.id] = sr.getCSS();
        return css + this.cssCache[sr.id];
      }
    }, '');

    this.styleRuleTracker = styleRules;

    return css;

  }


  // DEPENDENCIES
  /////////////////////////////////////////////////////////////////////////

  getDependency(id) {
    return this.getDependencies().find(d => d.id === id) || {};
  }

  getDependencyByName(name) {
    return this.getDependencies().find(d => d.pkg.name === name) || {};
  }

  getDependencies() {
    const that = this;
    return getDataPoints(store.getState(), {
      dataPointType: 'dependency'
    }).map(d => new Dependency({
      ...d,
      getProject() {
        return that;
      }
    }));
  }

  // ACTIONS
  /////////////////////////////////////////////////////////////////////////

  getAction(id) {
    return this.getActions().find(fn => fn.id === id) || {};
  }

  getActions() {
    const that = this;
    return getDataPoints(store.getState(), {
      dataPointType: 'action'
    }).map(action => new Action({
      ...action,
      getProject() {
        return that;
      }
    })) || [];
  }

  // SLICES
  /////////////////////////////////////////////////////////////////////////

  getSlice(id) {
    return this.getSlices().find(slice => slice.id === id) || {};
  }

  getSlices() {
    const that = this;
    return getDataPoints(store.getState(), {
      dataPointType: 'slice'
    }) || [];
  }

  getSliceProp(id) {
    return this.getSliceProps().find(slice => slice.id === id) || {};
  }

  getSliceProps() {
    const that = this;
    return getDataPoints(store.getState(), {
      dataPointType: 'sliceProp'
    }) || [];
  }


  // ATTRIBUTES
  /////////////////////////////////////////////////////////////////////////

  getAttribute(id) {
    return this.getAttributes().find(item => item.id === id) || {};
  }

  getAttributes() {
    const that = this;
    return getDataPoints(store.getState(), {
      dataPointType: 'attribute'
    }).map(item => new Attribute({
      ...item,
      getProject() {
        return that;
      }
    })) || [];
  }


  // EVENT HANDLERS
  /////////////////////////////////////////////////////////////////////////

  getEventHandler(id) {
    return this.getEventHandlers().find(eventHandler => eventHandler.id === id) || {};
  }

  getEventHandlers() {
    const that = this;
    return getDataPoints(store.getState(), {
      dataPointType: 'eventHandler'
    }).map(eventHandler => new EventHandler({
      ...eventHandler,
      getProject() {
        return that;
      }
    })) || [];
  }

  // REDUCERS
  /////////////////////////////////////////////////////////////////////////

  getReducer(id) {
    return this.getReducers().find(reducer => reducer.id === id) || {};
  }

  getReducers() {
    const that = this;
    return getDataPoints(store.getState(), {
      dataPointType: 'reducer'
    }).map(reducer => new Reducer({
      ...reducer,
      getProject() {
        return that;
      }
    })) || [];
  }

  // SELECTORS
  /////////////////////////////////////////////////////////////////////////

  getSelector(id) {
    return this.getSelectors().find(selector => selector.id === id) || {};
  }

  getSelectors() {
    const that = this;
    return getDataPoints(store.getState(), {
      dataPointType: 'selector'
    }).map(item => new Selector({
      ...item,
      getProject() {
        return that;
      }
    })) || [];
  }

  // FUNCTIONS
  /////////////////////////////////////////////////////////////////////////

  getFunction(id) {
    return this.getFunctions().find(fn => fn.id === id) || {};
  }

  getFunctions() {
    const that = this;
    return getDataPoints(store.getState(), {
      dataPointType: 'function'
    }).map(fn => new Fn({
      ...fn,
      getProject() {
        return that;
      }
    })) || [];
  }


  stringReplace(value, {map,ignore,loopContext, responseMap, event = {}}) {
    // if (value && typeof value === 'string' && value.includes('${')) {
    //
    //   const valueMap = {};
    //
    //   value.split('${').forEach(substring => {
    //     // const valueArray = [];
    //     if (substring.includes('}')) {
    //       let inputValue = '';
    //       const splitBracket = substring.split('}');
    //       const inputString = splitBracket[0];
    //       const [inputType, componentId, propId] = inputString.split('.');
    //
    //       switch (inputType) {
    //         case 'props': {
    //           if (componentId && propId) {
    //             const inputComponent = this.getComponentByName(componentId);
    //             const inputProp = inputComponent.getPropByName(propId);
    //
    //             if (inputProp) {
    //               // if (propId === 'loop' && loopContext && loopContext[componentId]) {// && inputProp.bindToLoop && loopContext[componentId].hasOwnProperty(inputProp.name)) {
    //               //   inputValue = loopContext[componentId][loopProp];
    //               // } else {
    //               inputValue = inputProp.getValue();
    //               // }
    //             }
    //           }
    //           break;
    //         }
    //         case 'context': {
    //           if (componentId && propId) {
    //             if (loopContext && loopContext[componentId]) {// && inputProp.bindToLoop && loopContext[componentId].hasOwnProperty(inputProp.name)) {
    //               inputValue = loopContext[componentId][propId];
    //             }
    //           }
    //           break;
    //         }
    //         case 'event': {
    //           if (componentId && !propId) {
    //             inputValue = event[componentId];
    //           } else if (componentId && event[componentId] && propId) {
    //             inputValue = event[componentId][propId];
    //           }
    //           break;
    //         }
    //         default:
    //           break;
    //       }
    //
    //       valueMap['${' + inputString + '}'] = inputValue === undefined ? '' : inputValue;
    //     }
    //   });
    //
    //   Object.entries(valueMap).forEach(([k, v]) => {
    //     value = value.split(k).join(v);
    //   });
    // }

    if (value && typeof value === 'string' && value.includes('${')) {
      const props = this.getComponentItems().reduce((m, c) => {
        if (!m[c.name]) {
          m[c.name] = c.getPropsMap(null,() => { return true },{map:m,ignore});
        }
        return m;
      }, map || {});

      if (value.includes('context.') && !loopContext) {
        return value;
      } else {
        const code = new Function(['props','context','event'], `"use strict"; return \`${value}\`;`);
        return code.apply(null, [props,loopContext || {},event]);
      }
    } else {
      return value;
    }

    // return value;
  }

  // async uploadFile(file) {
  //   const assets = new Assets({
  //     projectId: this.id
  //   });
  //
  //   const newAsset = await assets.uploadFile(file);
  //
  //   return newAsset;
  // }
  //
  // requestAssets() {
  //   const assets = new Assets({
  //     projectId: this.id
  //   });
  //
  //   if (!this.assetsCalled) {
  //     assets.getAssets(assets => {
  //       this.setCache({
  //         assets
  //       });
  //     });
  //   }
  //
  //   this.assetsCalled = true;
  // }

}
