import { DataPoint } from '../DataPoint/class';

export class ComponentProp extends DataPoint {

  constructor(props) {
    super(props);

    Object.keys(props).forEach(key => {
      this[key] = props[key];
    });
  }

  getComponent() {
    const project = this.getProject();
    return project.getComponent(this.componentId);
  }

  remove() {
    const project = this.getProject();
    const relatedEventHandlers = project.getEventHandlers().filter(eh => (eh.propIds || []).includes(this.id));

    // remove from event handlers
    relatedEventHandlers.forEach(eh => {
      eh.removeProp(this.id);
    });

    super.remove();
  }


}
