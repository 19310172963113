import React from 'react';

//state management
import { connect } from 'react-redux'

// components
import ComponentItems from '../ComponentItems';


class Component extends React.PureComponent {

  constructor(props) {
    super(props);

    this.componentRef = React.createRef();

    this.component = this.props.project.getComponentItem(props.id, {loopContext: props.loopContext});
    this.element = this.component.getElement();

  }

  handleElementEvents(boundEvent, event) {
    event.persist();
    const  { project, loopContext, componentProps } = this.props;
    const projectState = project.getState();
    const component = project.getComponentItem(this.props.id, {loopContext});

    const eventHandlers = component.getEventHandlers();
    const type = event.type;
    const elementEvent = boundEvent || component.getElement().events.find(e => e.type === type) || {};// && e.target === target);
    const filteredEventHandlers = eventHandlers.filter(eh => eh.eventId === elementEvent.id);

    if (elementEvent.stopPropagation) {
      // event.stopPropagation();
    }

    if (type === 'click' && projectState.inspectComponentMode) {
      event.preventDefault();
      event.stopPropagation();
      project.inspectElement({
        componentId: component.id
      });
      project.setState({
        inspectComponentId: null
      });
      return false;
    }

    if (type === 'mouseover' && projectState.inspectComponentMode && projectState.inspectComponentId !== component.id) {
      event.preventDefault();
      event.stopPropagation();

      project.setState({
        inspectComponentId: component.id
      });
    }

    if (type === 'mouseout' && projectState.inspectComponentMode && projectState.inspectComponentId === component.id) {
      event.preventDefault();
      event.stopPropagation();
      project.setState({
        inspectComponentId: null
      });
    }

    // if (elementEvent.autoMap && !component.isInLoop()) {
    //   const autoMapProp = component.getAttributeByName(elementEvent.autoMap);
    //   autoMapProp.update({
    //     value: event.target.value
    //   });
    // }

    filteredEventHandlers.forEach(eh => {
      eh.execute({ event, loopContext, componentProps });
    });
  }


  render() {

    const { project, id, loopContext, textContent, attributes, isVisible, componentProps } = this.props;
    const tagName = this.component.getTagName();
    const TagName = tagName || React.Fragment;

    // const attributes = this.component.getAttributes({loopContext: this.props.loopContext});

    const processedAttributes = Object.entries(JSON.parse(attributes)).reduce((map, [key, attribute]) => {
      if (typeof attribute === 'object' && attribute._isEvent) {
        map[key] = this.handleElementEvents.bind(this, attribute);
      } else {
        map[key] = attribute;
      }
      return map;
    }, {});


    return isVisible ? (
      <React.Fragment>
        {this.element.allowChildComponents ? (
          <TagName ref={tagName ? this.componentRef : undefined} {...processedAttributes}>{!!textContent && textContent}
            <ComponentItems project={project} id={id} loopContext={loopContext} componentProps={componentProps} />
          </TagName>
        ) : textContent ? (
          <TagName ref={tagName ? this.componentRef : undefined} {...processedAttributes}>{textContent}</TagName>
        ) : (
          <TagName ref={tagName ? this.componentRef : undefined} {...processedAttributes} />
        )}

      </React.Fragment>
    ) : '';
  }
}

const mapStateToProps = (state, props) => {

  const { project, id, loopContext, componentProps } = props;

  const component = project.getComponentItem(id, {loopContext});
  const textContent = component.getTextContent();

  const attributes = JSON.stringify(component.getHtmlAttributes({loopContext, componentProps}));

  const isVisible = component.isVisible();

  return { textContent, attributes, isVisible };
}

export default connect(
  mapStateToProps,
  {  }
)(Component);
