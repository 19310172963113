import uuid from 'uuid';
import store from '../redux/store';
import { getState, getStateFromSelector } from '../State/slice';

import { DataPoint } from '../DataPoint/class';
import { Property } from '../ComponentProp/class';
import { Style } from '../ComponentStyles/class';
import { ComponentAction } from '../ComponentAction/class';
import { updateComponentItem } from './slice';
// import { getElement } from '../Elements/elements.store.js';
import elements from '../elements.json';


import cssMap from '../ComponentStyles/cssMap.json';

export class ComponentItem extends DataPoint {

  constructor(props) {
    super(props);
    Object.keys(props).forEach(key => {
      this[key] = props[key];
    });

    this.properties = this.properties || [];
  }

  toObj() {
    return Object.keys(this).reduce((map, key) => {
      if (typeof this[key] !== 'function') {
        map[key] = this[key];
      }
      return map;
    }, {});
  }

  update(data) {
    store.dispatch(updateComponentItem(this.id, data));
  }

  getName() {
    if (this.componentId) {
      const project = this.getProject();
      return project.getComponent(this.componentId).name;
    } else {
      return this.name;
    }
  }

  getLoopName() {
    const name = this.getName();
    return name.charAt(0).toLowerCase() + name.substring(1);
  }


  // ATTRIBUTES
  ////////////////////////////////////

  getAttributes() {
    const project = this.getProject();
    return project.getAttributes().filter(a => a.componentItemId === this.id);
  }

  getAttributeByName(name) {
    const project = this.getProject();
    return project.getAttributes().find(a => a.componentItemId === this.id && a.name === name);
  }

  getHtmlAttributes({loopContext, componentProps}) {
    const element = this.getElement();

    if (element.tagName) {
      //const props = this.getPropsMap(loopContext, (p) => p.isAttribute && p.name !== 'class' && (p.getValue() || p.type !== 'bool'), { useReactName: true });
      const element = this.getElement() || {};
      const props = JSON.parse(JSON.stringify(element.properties || []));
      const attributes = this.getAttributes();
      const attributeMap = attributes.concat(props.filter(p => p.value && !attributes.find(a => a.name === p.name))).reduce((map, a) => {
        const name = a.reactName || (props.find(p => p.name === a.name) || {}).reactName || a.name;
        map[name] = a.getValue ? a.getValue({loopContext, componentProps}) : a.value;
        return map;
      }, {});
      const events = this.getEvents().filter(e => e.type !== 'init').reduce((eventsMap, event) => {
        eventsMap[event.id] = {...event, _isEvent: true};//this.handleElementEvents.bind(this, event);
        return eventsMap;
      }, {});

      return {
        id: this.getHTMLId(),
        className: this.getHTMLClassNames(loopContext),
        ...attributeMap,
        ...events
      }
    } else {
      return {};
    }
  }
  handleElementEvents(boundEvent, event) {
    event.persist();
    const project = this.getProject();
    const projectState = project.getState();

    const componentActions = this.getActions();
    const type = event.type;
    const elementEvent = boundEvent || this.getElement().events.find(e => e.type === type) || {};// && e.target === target);

    if (elementEvent.stopPropagation) {
      // event.stopPropagation();
    }

    if (type === 'click' && projectState.inspectComponentMode) {
      event.preventDefault();
      event.stopPropagation();
      project.inspectElement({
        componentId: this.id
      });
      project.setState({
        inspectComponentId: null
      });
      return false;
    }

    if (type === 'mouseover' && projectState.inspectComponentMode && projectState.inspectComponentId !== this.id) {
      event.preventDefault();
      event.stopPropagation();

      project.setState({
        inspectComponentId: this.id
      });
    }

    if (type === 'mouseout' && projectState.inspectComponentMode && projectState.inspectComponentId === this.id) {
      event.preventDefault();
      event.stopPropagation();
      project.setState({
        inspectComponentId: null
      });
    }

    if (elementEvent.autoMap && !this.isInLoop()) {
      const autoMapProp = this.getProp(elementEvent.autoMap);
      autoMapProp.update({
        value: event.target.value
      });
    }
    const eventActions = componentActions.filter(action => action.eventId === elementEvent.id);

    eventActions.forEach(action => {
      action.execute({ event });
    });
  }

  getEventHandlers() {
    const project = this.getProject();
    const ids = this.eventHandlerIds || [];
    return ids.map(id => project.getEventHandler(id));
  }

  getTextContent() {
    const project = this.getProject();

    if (this.enableTextContent) {
      if (this.textContentStateConnectionId && this.textContentStateConnectionType) {
        if (this.textContentStateConnectionType === 'slice') {
          const sliceProp = project.getSliceProp(this.textContentStateConnectionId);
          const slice = project.getSlice(sliceProp.sliceId);
          const state = getState(store.getState());
          return state[slice.name][sliceProp.name];
        } else if( this.textContentStateConnectionType === 'selector') {
          const selector = project.getSelector(this.textContentStateConnectionId);
          const slices = project.getSlices().filter(slice => (selector.sliceIds || []).includes(slice.id));
          const componentItems = project.getComponentItems();
          return getStateFromSelector(store.getState(), {context: this.loopContext, selector, slices, componentItems});
        }
      } else if (this.textContent) {
        return this.textContent;
      }
    }
  }

  getTagName() {
    const element = this.getElement();
    return this.tagName || element.tagName;
  }

  // EVENTS/ACTIONS
  ////////////////////////////////////////

  getEvents() {
    return this.getElement().events || [];
  }

  getActions(filter) {
    const that = this;
    return Object.entries(this.actions || {}).filter(filter || (([id, action]) => true)).map(([id, action]) => new ComponentAction({
      id,
      ...action,
      parentId: that.id,
      loopContext: this.loopContext,
      getProject: that.getProject,
      update(data) {
        that.update({
          actions: {
            ...(that.actions || {}),
            [this.id]: {
              ...(that.actions || {})[this.id],
              ...data
            }
          }
        });
      }
    }));
  }

  createAction(data) {
    const id = uuid.v4();
    this.update({
      actions: {
        ...(this.actions || {}),
        [id]: {
          ...data
        }
      }
    });
  }

  removeAction(id) {
    const actions = JSON.parse(JSON.stringify(this.actions || {}));

    if (actions[id]) {
      delete actions[id];

      this.update({
        actions
      });
    }
  }

  getAction(id) {
    return this.getActions().find(action => action.id === id);
  }


  isVisible() {
    const project = this.getProject();
    if (this.enableVisibility && this.visibilityStateConnectionId) {
      if (this.visibilityStateConnectionType === 'slice') {
        const sliceProp = project.getSliceProp(this.visibilityStateConnectionId);
        const slice = project.getSlice(sliceProp.sliceId);
        const state = getState(store.getState());
        return state[slice.name][sliceProp.name];
      } else if( this.visibilityStateConnectionType === 'selector') {
        const selector = project.getSelector(this.visibilityStateConnectionId);
        const slices = project.getSlices().filter(slice => (selector.sliceIds || []).includes(slice.id));
        const componentItems = project.getComponentItems();
        return getStateFromSelector(store.getState(), {context: this.loopContext, selector, slices, componentItems});
      }
    } else {
      return true;
    }
  }

  getLoop() {
    return this.loop;
  }

  getLoopData() {
    const project = this.getProject();
    if (this.loopStateConnectionId) {
      if (this.loopStateConnectionType === 'slice') {
        const sliceProp = project.getSliceProp(this.loopStateConnectionId);
        const slice = project.getSlice(sliceProp.sliceId);
        const state = getState(store.getState());
        return state[slice.name][sliceProp.name];
      } else if( this.loopStateConnectionType === 'selector') {
        const selector = project.getSelector(this.loopStateConnectionId);
        const slices = project.getSlices().filter(slice => (selector.sliceIds || []).includes(slice.id));
        const componentItems = project.getComponentItems();
        return getStateFromSelector(store.getState(), {context: this.loopContext, selector, slices, componentItems});
      }
    }
  }

  hasLoopData() {
    const loopData = this.getLoopData();
    return loopData && loopData.length;
  }

  toggleLoop(event) {
    this.update({
      enableLoop: event._value
    });
  }

  isLoopEnabled() {
    return this.enableLoop;
  }

  isInLoop() {
    const parent = this.getParent();
    if (this.isLoopEnabled() || (parent.isInLoop && parent.isInLoop())) {
      return true;
    } else {
      return false
    }
  }

  getLoopParent() {
    const parent = this.getParent();
    if (this.isLoopEnabled()) {
      return this;
    } else if (parent.getLoopParent) {
      return parent.getLoopParent();
    }
  }


  // getAncestors() {
  //   const parent = this.getParent();
  //   let ancestors = [];
  //
  //   if (parent) {
  //     ancestors.push(parent);
  //     ancestors = ancestors.concat(parent.getAncestors());
  //   }
  //
  //   return ancestors;
  // }

  getParent() {

    const project = this.getProject();
    const dp = project.getDataPoint({
      id: this.parentId
    });

    return dp;
  }

  setParent(parentId, index) {
    const project = this.getProject();
    const currentParent = this.getParent();
    const newParent = project.getComponent(parentId);

    newParent.addChild(this.id, index);
    currentParent.removeChild(this.id);
    this.update({
      parentId
    });
  }



  getChildren() {
    return this.getComponentItems();
  }

  getComponentItems() {
    if (this.items.length) {
      const project = this.getProject();
      const unsortedComponents = project.getComponentItems().filter(c => this.items.includes(c.id));
      return this.items.map(id => unsortedComponents.find(c => c.id === id));
    } else {
      return [];
    }
  }

  getAllChildren(id) {
    return this.getChildren();
  }

  getDescendants(id) {
    let descendants = [];
    this.getChildren(id || this.id).forEach(dp => {
      descendants.push(dp);
      if (dp.getElement().allowChildren) {
        descendants = descendants.concat(this.getDescendants(dp.id));
      }
    });

    return descendants;
  }

  allowChildComponents() {
    return !this.templateId && this.getElement().allowChildComponents;
  }

  getElement() {
    return elements.find(element => element.id === this.elementId) || {};//getElement(this.elementId) || {};
  }

  getHTMLId() {
    return `component-${this.id}`;
  }

  getHTMLClassNames(loopContext) {
    const baseClassName = this.getBaseClassName();
    const classNameProp = this.getAttributeByName('class');
    return `${baseClassName} ${this.name} ${classNameProp ? classNameProp.getValue({loopContext}) : ''}`;//this.getClassNames().reduce((classNames, c) => `${c.name} ${classNames}`, this.getBaseClassName());
  }

  getBaseClassName() {
    return `component-${this.elementId}`;
  }

  getCSSSelector() {
    const element = this.getElement();
    return this.styleSelector || element.styleSelector || `.${this.name}`;
  }

  getIcon() {
    const element = this.getElement();
    if (this.templateId) {
      return 'bookmark_border';
    } else if(element) {
      return element.icon;
    }
  }

  isSelected() {
    const project = this.getProject();
    return project.getState().selectedComponentId === this.id;
  }

  select() {
    const project = this.getProject();
    project.setState({
      selectedComponentId: this.id
    });
  }

  // isVisible() {
  //   const state = this.getState();
  //   return !state.hidden;
  // }

  hasBorders() {
    // const parentComponent = this.getParentComponent();
    return false;//parentComponent.isSelected();
  }

  getIndex() {
    const parent = this.getParent();
    return parent.getComponentIndex(this.id);
  }

  getDuplicateData() {
    const project = this.getProject();
    const clone = JSON.parse(JSON.stringify(project.getDataPoint({ id: this.id })));

    // delete id
    delete clone.id;
    // delete firebase id
    delete clone.firebaseId;
    // store the original id
    clone._duplicateId = this.id;

    return clone;
  }


}
