import { CREATE_DATA_POINT, CREATE_DATA_POINTS, UPDATE_DATA_POINT, REMOVE_DATA_POINT, REMOVE_DATA_POINTS, REPLACE_DATA_POINTS, ADD_DATA_POINTS_HISTORY } from './actions';
import { UPDATE_PROJECT_STATE } from '../Project/actions';
import ArrayStore from '../Array.store';

import { DataPoint } from './class';
import { Component } from '../Component/class';

const arrayStore = new ArrayStore({
  typeMap: {
    [CREATE_DATA_POINT]: 'CREATE',
    [CREATE_DATA_POINTS]: 'CREATE_MULTIPLE',
    [UPDATE_DATA_POINT]: 'UPDATE',
    [REMOVE_DATA_POINT]: 'REMOVE',
    [REMOVE_DATA_POINTS]: 'REMOVE_MULTIPLE'
  }
});

// selectors

export const getAllDataPoints = store => {
  return arrayStore.getAllItems(store.dataPoints[store.dataPoints.activeArray]);
};

export const getDataPoints = (store, filter) => {
  return arrayStore.getItems(store.dataPoints[store.dataPoints.activeArray], filter).map(dp => new DataPoint(dp));
};

export const getDataPoint = (store, filter) => {
  return new DataPoint(arrayStore.getItem(store.dataPoints[store.dataPoints.activeArray], filter));
};

// history

export const getDataPointHistory = store => {
  return store.dataPoints.history;
};

// Components
export const getComponent = (store, filter) => {
  return new Component(arrayStore.getItem(store.dataPoints[store.dataPoints.activeArray], {
    ...filter,
    dataPointType: 'component'
  }));
};
export const getComponents = (store, filter) => {
  return arrayStore.getItems(store.dataPoints[store.dataPoints.activeArray], {
    ...filter,
    dataPointType: 'component'
  });//.map(component => new Component(component));
};




export const getDataPointChildren = (store, id) => {
  return arrayStore.getItems(store.dataPoints[store.dataPoints.activeArray], {
    parent: id
  });
};

// reducers

export default function(state = {
  activeArray: 'main',
  main: [],
  preview: [],
  history: {
    before: [],
    after: []
  }
}, action) {

  if (action.type === UPDATE_PROJECT_STATE) {

    const { newState } = action.payload;

    if (Object.keys(newState).includes('previewMode')) {

      if (newState.previewMode) {
        return {
          ...state,
          preview: JSON.parse(JSON.stringify(state.main)),
          activeArray: 'main'//'preview'
        }
      } else {
        return {
          ...state,
          activeArray: 'main'
        }
      }
    } else {
      return state;
    }

  } else if (action.type === REPLACE_DATA_POINTS) {

    const { dataPoints, options } = action.payload;

    const clonedHistoryBefore = JSON.parse(JSON.stringify(state.history.before || []));
    const clonedHistoryAfter = JSON.parse(JSON.stringify(state.history.after || []));

    if (options && options.undo) {
      clonedHistoryBefore.pop();
      clonedHistoryAfter.push(state.main);
    } else if (options && options.redo) {
      clonedHistoryAfter.pop();
      clonedHistoryBefore.push(state.main);
    }

    return {
      ...state,
      main: JSON.parse(JSON.stringify(dataPoints)),
      history: {
        ...state.history,
        before: clonedHistoryBefore,
        after: clonedHistoryAfter
      }
    }

  } else if (action.type === ADD_DATA_POINTS_HISTORY) {

    const clonedHistoryBefore = JSON.parse(JSON.stringify(state.history.before || []));
    const undoLimit = 20;
    const amountOverLimit = clonedHistoryBefore.length - undoLimit + 1;

    if (amountOverLimit > 0) {
      clonedHistoryBefore.splice(0, amountOverLimit);
    }

    return {
      ...state,
      history: {
        ...state.history,
        before: [...clonedHistoryBefore, state.main],
        after: []
      }
    }

  } else {
    return {
      ...state,
      [state.activeArray]: arrayStore.reducer(state[state.activeArray], action.type, action.payload)
    };
  }
}
