import React from 'react';

// state management
import { connect } from 'react-redux';

class ExternalScripts extends React.PureComponent {

  render() {
    return (
      <React.Fragment>
        {this.props.externalScripts.map(es => (
          <script key={es.id} src={es.link} ></script>
        ))}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, props) => {
  const externalScripts = props.project.getExternalScripts();
  return { externalScripts };
}

export default connect(
  mapStateToProps,
  { }
)(ExternalScripts);
