import React from 'react';

import {
  // BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  Redirect
} from 'react-router-dom';

import uuid from 'uuid';
import appSettings from '../appSettings';

// components
import Component from '../Component';
import ComponentOverlay from '../ComponentOverlay';
import ExternalStyles from '../ExternalStyles';
import ExternalScripts from '../ExternalScripts';
import { ReactComponent as Logo } from '../logo_white.svg';

// state management
import { connect } from 'react-redux';
import { replaceDataPoints } from '../DataPoint/actions';
import { updateProjectState } from '../Project/state';
import { replaceComponents } from '../Component/slice';
import { replaceComponentItems } from '../ComponentItem/slice';
import { replaceStyleRules } from '../StyleRule/slice';


class Stage extends React.PureComponent {

  async componentDidMount() {
    const that = this;

    const isOnlinePreviewLocal = window.location.href.includes(appSettings.functionsLocalUrl);
    const isOnlinePreviewProduction = window.location.href.includes(appSettings.functionsProductionUrl);
    const functionsUrl = isOnlinePreviewLocal ? appSettings.functionsLocalUrl : appSettings.functionsProductionUrl;

    if (isOnlinePreviewLocal || isOnlinePreviewProduction) {
      const splitUrl = window.location.href.split('p=');
      const previewId = splitUrl.length > 1 ? splitUrl[1].split('&')[0] : undefined;
      let projectFailed;

      if (previewId) {
        try {
          const request = await fetch(`${functionsUrl}/previews/${previewId}`);
          const jsonData = await request.json();
          const data = JSON.parse(jsonData);
          const [projectData, dependenciesData] = data;
          const datapoints = that.props.project.convertToDatapoints(projectData);
          that.props.replaceComponents(projectData.components);
          that.props.replaceComponentItems(projectData.componentItems);
          that.props.replaceStyleRules(projectData.styleRules);
          that.props.replaceDataPoints(datapoints || []);
          that.props.project.updateMetaData();
          that.props.project.setCache({ dependenciesData });
          that.props.project.setState({
            dependenciesLoaded: true
          });
        } catch (error) {
          projectFailed = true;
        }
      } else {
        projectFailed = true;
      }

      if (projectFailed) {
        // document.querySelector('html').style.backgroundColor = 'hsla(222,90%,65%,1)';
        const styles = import('./styles.css');
        that.props.project.setState({
          previewFailed: true
        });
      }
    }

    const handleMessage = (msg) => {
      let data;
      let newState = false;

      if (msg.data) {
        data = msg.data;
      } else {
        data = msg.detail;
      }

      if (data.projectData) {
        that.props.project.id = data.projectData.id;
        const datapoints = that.props.project.convertToDatapoints(data.projectData);
        that.props.replaceComponents(data.projectData.components);
        that.props.replaceComponentItems(data.projectData.componentItems);
        that.props.replaceStyleRules(data.projectData.styleRules);
        that.props.replaceDataPoints(datapoints || []);
        that.props.project.updateMetaData();
      }
      if (data.projectState) {
        newState = {
          ...(newState || {}),
          ...(data.projectState || {})
        }
        // that.props.updateProjectState(data.projectState || {});
      }

      if (data.refresh) {
        window.location.reload();
      }

      if (data.dependenciesData) {
        newState = {
          ...(newState || {}),
          dependenciesLoaded: true,
          _cache: {
            ...that.props.project.getCache(),
            dependenciesData: data.dependenciesData
          }
        }
        // that.props.project.setState({
        //   dependenciesLoaded: true
        // });
        // that.props.project.setCache({
        //   dependenciesData: data.dependenciesData
        // });
      }

      if (newState) {
        that.props.updateProjectState(newState);
      }
    }

    window.removeEventListener("message", handleMessage);
    window.addEventListener("message", handleMessage, false);
  }

  render() {
    return this.props.previewFailed ? (
      <div className="__dk-preview-failed" style={{opacity: 0}}>
        <div className="__dk-preview-failed-content">
          <div className="__dk-preview-failed-heading">
            <h1>Project not found</h1>
          </div>
          <div className="__dk-preview-failed-body">Make sure the link you copied is correct. If the project was updated this link may have expired.</div>
        </div>
      </div>
    ) : !this.props.stageReady ? '' : (
      <React.Fragment>
        <ExternalStyles project={this.props.project} />
        <style>{this.props.css}</style>
        <Component project={this.props.project} id={'root'} />
        <ComponentOverlay project={this.props.project} />
        <ExternalScripts project={this.props.project} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, props) => {

  const data = props.project.getData();
  const projectState = props.project.getState();

  let css;
  let previewMode;
  let functions;

  let previewFailed;

  const stageReady = !!data.length && projectState.dependenciesLoaded;

  if (stageReady) {

    css = props.project.getCSS();//props.project.getComponentsCSS();
    //styleVariables = props.project.getStyleVariablesCss();
    previewMode = projectState.previewMode;
    // functions = props.project.getFunctions();

  } else if (projectState.previewFailed) {
    previewFailed = true;
  }

  return { stageReady, css, previewMode, previewFailed };
}

export default connect(
  mapStateToProps,
  { replaceComponents, replaceComponentItems, replaceStyleRules, replaceDataPoints, updateProjectState }
)(Stage);
