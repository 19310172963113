import store from '../redux/store';
import { setState } from '../State/slice';

export class Action {

  constructor(props) {
    Object.keys(props).forEach(key => {
      this[key] = props[key];
    });
  }

  getReducers() {
    const project = this.getProject();
    return project.getReducers().filter(r => (this.reducerIds || []).includes(r.id));
  }

  execute({ event, loopContext }, actionId, options) {
    const reducers = this.getReducers();
    let payload;

    if (this.code) {
      payload = this.executeFunction({...this, options});
    }

    reducers.forEach(reducer => {
      const slice = reducer.getSlice();
      if (slice) {
        store.dispatch(setState(event, slice, reducer.code, payload || options));
      }
    });
  }

  executeFunction({ event, loopContext, code, async, dependencyIds = [], loopIds, sliceIds, actionIds, options }) {
    const project = this.getProject();
    const actions = project.getActions();
    // get dependencies
    const requireDataAll = (project.getCache().dependenciesData || []);
    const baseModules = dependencyIds.map(dependencyId => {
      const pkg = project.getDependency(dependencyId);
      const requireData = requireDataAll.find(d => d.data.path === pkg.name);

      let module = { exports: {} };
      const process = { env: {} };

      let requireFunction;

      requireFunction = (data, path) => {
        const dependency = data.dependencies.find(df => df.path === path);
        let code = new Function(['module', 'exports', 'require', 'process'], requireData.files[dependency.fullPath].file);
        code.apply(null, [module, module.exports, requireFunction.bind(this, requireData.files[dependency.fullPath]), process])
        return module.exports;
      };

      let code = new Function(['module', 'exports', 'require', 'process'], requireData.files[requireData.data.fullPath].file);
      code.apply(null, [module, module.exports, requireFunction.bind(this, requireData.files[requireData.data.fullPath]), process]);

      return {
        pkg,
        module
      };
    });

    const state = store.getState().state;
    const loopContextKeys = loopIds ? loopIds.map(id => (project.getComponentItem(id) || {}).getLoopName()) : [];
    const loopContextValues = loopIds ? loopIds.map(id => loopContext[(project.getComponentItem(id) || {}).getName()]) : [];
    const sliceKeys = sliceIds ? sliceIds.map(id => (project.getSlice(id) || {}).name) : [];
    const sliceValues = sliceIds ? sliceKeys.map(k => state[k]) : [];

    const dispatchActions = actions.filter(a => (actionIds || []).includes(a.id));
    const actionMap = dispatchActions.map(da => (actionOptions) => {this.execute({ event }, da.id, actionOptions)});
    const evalCode = new Function([...dispatchActions.map(da => da.name), 'payload', ...sliceKeys, ...loopContextKeys, ...baseModules.map(bm => bm.pkg.name)], `"use strict"; return ${async ? '(async' : '('} () => { ${code} \n })()`);
    return evalCode.apply(null, [...actionMap, options, ...sliceValues, ...loopContextValues, ...baseModules.map(bm => bm.module.exports)]);

  }

}
