import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'styleRules',
  initialState: {
    byId: {},
    allIds: []
  },
  reducers: {
    replaceStyleRules: {
      reducer(state, action) {
        const { data } = action.payload;
        state.byId = data.reduce((map, item) => {
          map[item.id] = item;
          return map;
        }, {});
        state.allIds = data.map(item => item.id);
      },
      prepare(data) {
        return { payload: { data } }
      }
    }
  }
});

export const { replaceStyleRules } = slice.actions;

export default slice.reducer;

// selectors
export const getStyleRule = (store, id) => store.styleRules.byId[id];
export const getStyleRulesArray = store => store.styleRules.allIds.map(id => store.styleRules.byId[id]);
