import { createSlice } from '@reduxjs/toolkit';

const componentItemSlice = createSlice({
  name: 'componentItems',
  initialState: {
    byId: {},
    allIds: []
  },
  reducers: {
    replaceComponentItems: {
      reducer(state, action) {
        const { data } = action.payload;
        state.byId = data.reduce((map, component) => {
          map[component.id] = component;
          return map;
        }, {});
        state.allIds = data.map(component => component.id);
      },
      prepare(data) {
        return { payload: { data } }
      }
    },
    updateComponentItem: {
      reducer(state, action) {
        const { id, data } = action.payload;
        state.byId[id] = {
          ...state.byId[id],
          ...data
        };
      },
      prepare(id, data) {
        return { payload: { id, data } }
      }
    }
  }
});

export const { replaceComponentItems, updateComponentItem } = componentItemSlice.actions;

export default componentItemSlice.reducer;

// selectors
export const getComponentItem = (store, id) => store.componentItems.byId[id];
export const getComponentItemByName = (store, name) => Object.entries(store.componentItems.byId).map(([id,c]) => c).find(c => c.name === name);
export const getComponentItemsArray = store => store.componentItems.allIds.map(id => store.componentItems.byId[id]);
