import uuid from 'uuid';
import store from '../redux/store';
import { setState } from '../State/slice';

export class ComponentAction {

  constructor(props) {
    Object.keys(props).forEach(key => {
      this[key] = props[key];
    });
  }

  getSelectedComponent() {
    const project = this.getProject();
    return project.getComponentItem(this.selectedComponentId);
  }

  getSelectedInputComponent() {
    const project = this.getProject();
    return project.getComponentItem(this.selectedInputComponentId);
  }

  getProp() {
    const component = this.getSelectedComponent();
    return component.getProp(this.propertyId);
  }

  setStyle(value) {
    const component = this.getSelectedComponent();
    return component.setStyle(this.styleId, value);
  }

  async requireRemote(fullPath, path, parentData) {
    path = path || fullPath;
    const response = await fetch(`https://unpkg.com/${fullPath.replace('./','')}`);
    const file = await response.text();

    const data = {
      fullPath,
      path,
      file,
      dependencies: []
    };

    const promises = file.split('require(').map(async (s, i) => {
      if (i) {
        const sPath = s.split(')')[0].replace(/"|'|`/g, '');
        const splitPath = fullPath.split('/');
        const newFullPath = sPath.includes('../') ? `${splitPath.length > 2 ? splitPath.slice(0,-2).join('/') : splitPath.length == 1 ? splitPath.slice(0,-1).join('/') : fullPath}/${sPath.replace('../','')}` : sPath.includes('./') ? `${splitPath.length > 1 ? splitPath.slice(0,-1).join('/') : fullPath}/${sPath.replace('./','')}` : sPath;

        try {
          const dep = await this.requireRemote(newFullPath, sPath, data);
          return dep;
        } catch (error) {
          return undefined;
        }
      }
    });

    data.dependencies = await Promise.all(promises);

    data.dependencies = data.dependencies.reduce((list, d) => {
      if (d) { list.push(d); }
      return list;
    }, []);

    return data;
  }

  execute({ event }, actionId, options) {
    const project = this.getProject();
    const actions = project.getActions();
    const action = project.getAction(actionId || this.actionId);
    const functions = project.getFunctions().filter(fn => (action.functionIds || []).includes(fn.id));

    functions.forEach(fn => {
      if (fn.type === 'reduce' && fn.sliceId) {
        const slice = project.getSlice(fn.sliceId);
        store.dispatch(setState(event, slice, fn.code, options));
      } else {

        // get dependencies
        const requireDataAll = (project.getCache().dependenciesData || []);
        const baseModules = (fn.imports || []).map((pkg) => {
          const requireData = requireDataAll.find(d => d.data.path === pkg.name);

          let module = { exports: {} };
          const process = { env: {} };

          let requireFunction;

          requireFunction = (data, path) => {
            const dependency = data.dependencies.find(df => df.path === path);
            let code = new Function(['module', 'exports', 'require', 'process'], requireData.files[dependency.fullPath].file);
            code.apply(null, [module, module.exports, requireFunction.bind(this, requireData.files[dependency.fullPath]), process])
            return module.exports;
          };

          let code = new Function(['module', 'exports', 'require', 'process'], requireData.files[requireData.data.fullPath].file);
          code.apply(null, [module, module.exports, requireFunction.bind(this, requireData.files[requireData.data.fullPath]), process]);

          return {
            pkg,
            module
          };
        });

        const state = store.getState().state;
        const loopContextKeys = fn.loopIds ? fn.loopIds.map(id => (project.getComponentItem(id) || {}).name) : [];
        const loopContextValues = fn.loopIds ? fn.loopIds.map(id => this.loopContext[(project.getComponentItem(id) || {}).name]) : [];
        const sliceKeys = fn.sliceIds ? fn.sliceIds.map(id => (project.getSlice(id) || {}).name) : [];
        const sliceValues = fn.sliceIds ? sliceKeys.map(k => state[k]) : [];

        if (fn.type === 'dispatch') {
          const dispatchActions = actions.filter(a => fn.dispatchActions.includes(a.id));
          const actionMap = dispatchActions.map(da => (actionOptions) => {this.execute({ event }, da.id, actionOptions)});
          const code = new Function([...dispatchActions.map(da => da.name), ...Object.keys(options || {}), ...sliceKeys, ...loopContextKeys, 'event', ...baseModules.map(bm => bm.pkg.name)], `"use strict"; ${fn.async ? '(async' : '('} () => {${fn.code}})()`);
          code.apply(null, [...actionMap, ...Object.entries(options || {}).map(([k,v]) => v), ...sliceValues, ...loopContextValues, event, ...baseModules.map(bm => bm.module.exports)]);
        } else {
          const options = {};
          const code = new Function(['options', ...loopContextKeys, 'event', ...baseModules.map(bm => bm.pkg.name)], `"use strict"; ${fn.async ? '(async' : '('} () => {${fn.code}})()`);
          code.apply(null, [options, ...loopContextValues, event, ...baseModules.map(bm => bm.module.exports)]);
        }
      }
    })
  }

  getParent() {
    const project = this.getProject();
    return project.getDataPoint({id:this.parentId});
  }

  getParentAction() {
    const component = this.getParent();
    return component.getAction(this.actionId);
  }

  getEndpoint() {
    const project = this.getProject();
    return project.getEndpoint(this.endpointId);
  }

  getFunction() {
    const project = this.getProject();
    return project.getFunction(this.functionId);
  }

  onSelectProp({ componentId, propertyId }) {
    this.update({
      selectedComponentId: componentId,
      propertyId
    });
  }

  onSelectInputProp({ componentId, propertyId }) {
    this.update({
      selectedInputComponentId: componentId,
      inputPropertyId: propertyId
    });
  }

  onSelectStyle({ componentId, styleId }) {
    this.update({
      selectedComponentId: componentId,
      styleId
    });
  }

  onSelectComponent(selectedComponentId) {
    this.update({ selectedComponentId });
  }

  onSelectInputComponent(selectedInputComponentId) {
    this.update({ selectedInputComponentId });
  }

  // loop Stuff

  mapToLoopProp(loopPropId, event) {
    this.update({
      valueMap: {
        ...this.valueMap,
        [loopPropId]: event._value
      }
    });
  }

}
