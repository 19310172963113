import React from 'react';

// state management
import { connect } from 'react-redux';

class ExternalStyles extends React.PureComponent {

  render() {
    return (
      <React.Fragment>
        {this.props.externalStyles.map(es => (
          <link key={es.id} href={es.link} rel="stylesheet" />
        ))}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, props) => {
  const externalStyles = props.project.getExternalStyles();
  return { externalStyles };
}

export default connect(
  mapStateToProps,
  { }
)(ExternalStyles);
