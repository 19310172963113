import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
// import { UPDATE_PROJECT } from './actions';
import Project from './class';


const projectSlice = createSlice({
  name: 'project',
  initialState: {},
  reducers: {
    updateProject(state, action) {
      const { projectData } = action.payload;
      Object.assign(state, projectData);
    }
  }
});

export const { updateProject } = projectSlice.actions;

export default projectSlice.reducer;

// selectors

export const getProjectMetaData = store => store.project || {};
const getProjectData = store => JSON.stringify(store.project || {});

export const getProject = createSelector(
  [getProjectData],
  project => new Project(JSON.parse(project))
);


// export default function(state = {}, action) {
//
//   switch (action.type) {
//
//     case UPDATE_PROJECT: {
//
//       const { projectData } = action.payload;
//
//       return {
//         ...state,
//         ...projectData
//       }
//     }
//
//     default:
//       return state;
//   }
// };
