import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import store from './redux/store';

import Stage from './Stage';
import Project from './Project/class';

ReactDOM.render(<Provider store={store}><Stage project={new Project({isStage: true})} /></Provider>, document.getElementById('root'));
