import store from '../redux/store';
import uuid from 'uuid';
import { DataPoint } from '../DataPoint/class';

export class EventHandler extends DataPoint {

  constructor(props) {
    super(props);

    Object.keys(props).forEach(key => {
      this[key] = props[key];
    });
  }

  getName() {
    const eventData = this.getEventData();
    return eventData.label;
  }

  getEventData() {
    const componentItem = this.getComponentItem();
    const element = componentItem.getElement();
    return element.events.find(e => e.id === this.eventId);
  }

  getComponentItem() {
    const project = this.getProject();
    return project.getComponentItem(this.componentItemId);
  }

  // Loops

  getLoop(id) {
    return this.getLoops().find(loop => loop.id === id);
  }
  getLoops() {
    const project = this.getProject();
    return project.getComponentItems().filter(loop => (this.loopIds || []).includes(loop.id));
  }

  // Actions

  getAction(id) {
    return this.getActions().find(da => da.id === id);
  }
  getActions() {
    const project = this.getProject();
    return project.getActions().filter(action => (this.actionIds || []).includes(action.id));
  }

  execute({ event, loopContext, componentProps = {} }, actionId, options) {
    const project = this.getProject();

    // get dependencies
    const requireDataAll = (project.getCache().dependenciesData || []);
    const baseModules = (this.dependencyIds || []).map(dependencyId => {
      const pkg = project.getDependency(dependencyId);
      const requireData = requireDataAll.find(d => d.data.path === pkg.name);

      let module = { exports: {} };
      const process = { env: {} };

      let requireFunction;

      requireFunction = (data, path) => {
        const dependency = data.dependencies.find(df => df.path === path);
        let code = new Function(['module', 'exports', 'require', 'process'], requireData.files[dependency.fullPath].file);
        code.apply(null, [module, module.exports, requireFunction.bind(this, requireData.files[dependency.fullPath]), process])
        return module.exports;
      };

      let code = new Function(['module', 'exports', 'require', 'process'], requireData.files[requireData.data.fullPath].file);
      code.apply(null, [module, module.exports, requireFunction.bind(this, requireData.files[requireData.data.fullPath]), process]);

      return {
        pkg,
        module
      };
    });

    const state = store.getState().state;
    const loopContextKeys = this.loopIds ? this.loopIds.filter(id => project.getComponentItem(id).id).map(id => (project.getComponentItem(id)).getLoopName()) : [];
    const loopContextValues = this.loopIds ? this.loopIds.filter(id => project.getComponentItem(id).id).map(id => loopContext[(project.getComponentItem(id)).getName()]) : [];
    const sliceKeys = this.sliceIds ? this.sliceIds.map(id => (project.getSlice(id) || {}).name) : [];
    const sliceValues = this.sliceIds ? sliceKeys.map(k => state[k]) : [];
    const propKeys = this.propIds ? this.propIds.map(id => (project.getComponentProp(id) || {}).name) : [];
    const propValues = this.propIds ? propKeys.map(k => componentProps[k]) : [];

    const dispatchActions = this.getActions();
    const actionMap = dispatchActions.map(da => (actionOptions) => {da.execute({ event }, da.id, actionOptions)});
    const code = new Function([...dispatchActions.map(da => da.name), ...Object.keys(options || {}), ...propKeys, ...sliceKeys, ...loopContextKeys, 'event', ...baseModules.map(bm => bm.pkg.name)], `"use strict"; ${this.async ? '(async' : '('} () => { ${this.code} \n })()`);
    code.apply(null, [...actionMap, ...Object.entries(options || {}).map(([k,v]) => v), ...propValues, ...sliceValues, ...loopContextValues, event, ...baseModules.map(bm => bm.module.exports)]);
  }

}
