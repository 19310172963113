import uuid from 'uuid';

export default class ArrayStore {

  constructor(props) {
    this.typeMap = props.typeMap;
    this.store = props.store
  }

  getAllItems(store) {
    return store || [];
  }

  getItem(store, filter) {
    const items = this.getItems(store, filter);

    if (items.length) {
      return items[0];
    } else {
      return {};
    }
  }

  getItems(store, filter) {
    return store.filter(typeof filter === 'function' ? filter : item => {
      let isMatch = true;

      Object.keys(filter).forEach((key) => {
        if (item[key] !== filter[key]) {
          isMatch = false;
        }
      });

      return isMatch;
    });
  }

  getTypeFromMap(type) {
    return this.typeMap[type];
  }

  reducer(state, type, payload) {

    const array = JSON.parse(JSON.stringify(state));
    const typeFromMap = this.getTypeFromMap(type);

    switch (typeFromMap) {

      case 'CREATE': {

        const { item, index } = payload;

        item['id'] = item.id || uuid.v4();

        if (index) {
          array.splice(index, 0, item);
        } else {
          array.push(item);
        }

        return array;
      }

      case 'CREATE_MULTIPLE': {

        const { items } = payload;

        items.forEach(item => {
          item['id'] = item.id || uuid.v4();
        });

        return array.concat(items);
      }

      case 'REMOVE': {

        const { id } = payload;

        return array.filter((item) => item.id !== id);
      }

      case 'REMOVE_MULTIPLE': {

        const { ids } = payload;

        return array.filter((item) => !ids.includes(item.id));
      }

      case 'UPDATE': {

        const { id, data } = payload;
        const item = array.find((item) => item.id === id);

        Object.assign(item, data);

        return array;
      }

      default:
        return state;
    }
  }
}
