import { DataPoint } from '../DataPoint/class';

export class Function extends DataPoint {

  constructor(props) {
    super(props);

    Object.keys(props).forEach(key => {
      this[key] = props[key];
    });
  }

  addImport(pkg) {

    const project = this.getProject();
    const dependencies = project.getDependencies();

    this.update({
      imports: [...(this.imports || []), pkg]
    });

    console.log(dependencies)
    console.log(pkg.name)

    if (!dependencies.find(d => d.pkg.name === pkg.name)) {
      // add to dependencies
      project.createDataPoint({
        dataPointType: 'dependency',
        pkg
      });
    }
  }

  removeImport(name) {

    const project = this.getProject();
    const dependency = project.getDependencyByName(name);

    this.update({
      imports: [...(this.imports || []).filter(fi => fi.name !== name)]
    });

    console.log(project.getFunctions())

    const allFunctionImports = project.getFunctions().map(fn => (fn.imports || []).map(fi => fi.name)).reduce((list, imports) => list.concat(imports), []);
    console.log(allFunctionImports);

    if (!allFunctionImports.includes(name)) {
      // remove from dependencies
      console.log('remove dependency');
      dependency.remove();
    }
  }

}
