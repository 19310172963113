import { combineReducers } from 'redux';
import project from '../Project/store';
import projectState from '../Project/state';
import components from '../Component/slice';
import componentItems from '../ComponentItem/slice';
import styleRules from '../StyleRule/slice';
import dataPoints from '../DataPoint/store';
import state from '../State/slice';

export default combineReducers({ project, projectState, components, componentItems, styleRules, dataPoints, state });
