import { DataPoint } from '../DataPoint/class';

export class Dependency extends DataPoint {

  constructor(props) {
    super(props);

    Object.keys(props).forEach(key => {
      this[key] = props[key];
    });
  }

}
