import { DataPoint } from '../DataPoint/class';

export class Reducer extends DataPoint {

  constructor(props) {
    super(props);

    Object.keys(props).forEach(key => {
      this[key] = props[key];
    });
  }

  getSlice() {
    const project = this.getProject();
    if (this.sliceId) {
      return project.getSlice(this.sliceId);
    }
  }

}
