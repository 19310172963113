// import firebase from 'firebase';
//import uuid from 'uuid';

export const defaultValues = {
  string: '',
  number: 0,
  bool: false
}

export const CREATE_DATA_POINT = 'CREATE_DATA_POINT';
export const createDataPoint = item => ({
  type: CREATE_DATA_POINT,
  payload: {
    item: {
      ...item
    }
  }
});

export const CREATE_DATA_POINTS = 'CREATE_DATA_POINTS';
export const createDataPoints = items => ({
  type: CREATE_DATA_POINTS,
  payload: {
    items
  }
});

export const REMOVE_DATA_POINT = 'REMOVE_DATA_POINT';
export const removeDataPoint = id => ({
  type: REMOVE_DATA_POINT,
  payload: {
    id
  }
});

export const REMOVE_DATA_POINTS = 'REMOVE_DATA_POINTS';
export const removeDataPoints = ids => ({
  type: REMOVE_DATA_POINTS,
  payload: {
    ids
  }
});

export const UPDATE_DATA_POINT = 'UPDATE_DATA_POINT';
export const updateDataPoint = (id, data) => ({
  type: UPDATE_DATA_POINT,
  payload: {
    id,
    data
  }
});

export const REPLACE_DATA_POINTS = 'REPLACE_DATA_POINTS';
export const replaceDataPoints = (dataPoints, options) => ({
  type: REPLACE_DATA_POINTS,
  payload: {
    dataPoints,
    options
  }
});

export const ADD_DATA_POINTS_HISTORY = 'ADD_DATA_POINTS_HISTORY';
export const addDataPointsHistory = actions => ({
  type: ADD_DATA_POINTS_HISTORY,
  payload: {
    actions
  }
});
