import { DataPoint } from '../DataPoint/class';

export class StyleRule extends DataPoint {
  constructor(props) {
    super(props);
    Object.keys(props).forEach(key => {
      this[key] = props[key];
    });
  }

  getSelector() {
    if (this.componentItemId) {
      const project = this.getProject();
      const componentItem = project.getComponentItem(this.componentItemId);
      return componentItem.getCSSSelector();
    } else if (this.parentStyleRuleId) {
      const project = this.getProject();
      const parentStyleRule = project.getStyleRule(this.parentStyleRuleId);
      return parentStyleRule.getSelector() + (this.selector || '');
    } else {
      return this.selector;
    }
  }

  // getPropertyData(propertyName) {
  //   return cssMap.find(m => m.cssProp === propertyName) || {};
  // }

  getPropertyValue(propertyName) {
    return (this.declarations || {})[propertyName];
  }

  getCSS() {
    const declarationsString = this.declarations ? Object.entries(this.declarations).reduce((declarationsString, [prop, val]) => {
      declarationsString += `${prop.replace(/[A-Z]/g, match => `-${match.toLowerCase()}`)}:${val};`;
      return declarationsString;
    }, '') : '';

    return `${this.mediaQuery ? `@media ${this.mediaQuery} {` : ''} ${this.getSelector()} {${declarationsString}} ${this.mediaQuery ? `}` : ''}`;
  }
}
