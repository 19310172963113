import store from '../redux/store';
// import { getProject } from '../Project/store';
import { removeDataPoints } from './actions';
import { getDataPoints } from './store';


export class DataPoint {
  constructor(props) {
    Object.keys(props).forEach(key => {
      this[key] = props[key];
    });
  }

  toObj() {
    return Object.keys(this).reduce((map, key) => {
      if (typeof this[key] !== 'function') {
        map[key] = this[key];
      }
      return map;
    }, {});
  }

  update(data, storeInCloud) {
    const project = this.getProject();
    project.updateDataPoint(this.id, data);

    // if (storeInCloud !== false) {
      // project.saveDataPoint(this.firebaseId, data);
    // }
  }

  setState(data) {
    const project = this.getProject();
    const projectState = project.getState();

    project.setState({
      [this.dataPointType]: {
        ...projectState[this.dataPointType],
        [this.id]: {
          ...(projectState[this.dataPointType] || {})[this.id],
          ...data
        }
      }
    });
  }

  getState() {
    const project = this.getProject();
    const projectState = project.getState();
    return (projectState[this.dataPointType] || {})[this.id] || {};
  }

  // getValue() {
  //   if (this.input === 'datapoint') {
  //     const state = store.getState();
  //     const input = getDataPoint(state, {
  //       id: this.inputDataPoint
  //     });
  //
  //     if (this.loopContext && this.loopContext.hasOwnProperty(input.name)) {
  //       return this.loopContext[input.name];
  //     }
  //     return input.getValue();
  //   } else {
  //     return this.value || this.defaultValue;
  //   }
  // }

  // getDuplicateData() {
  //   const clone = JSON.parse(JSON.stringify(this));
  //
  //   // delete id
  //   clone.id = uuid.v4();
  //
  //   // delete firebase id
  //   delete clone.firebaseId;
  //
  //   return clone;
  // }

  // duplicate(duplicateData) {
  //   const clone = duplicateData || this.getDuplicateData();
  //
  //   store.dispatch(createDataPoint(clone));
  //
  //   return clone;
  // }
  //
  // duplicateAll() {
  //   const cloneSelf = this.duplicate();
  //   const idMap = {
  //     [this.id]: cloneSelf.id
  //   };
  //
  //   this.getDescendants().forEach(dp => {
  //     const clone = dp.getDuplicateData();
  //     idMap[dp.id] = clone.id;
  //     clone.parent = idMap[clone.parent];
  //     dp.duplicate(clone);
  //   });
  //
  //   return {
  //     idMap
  //   }
  // }

  remove() {
    const project = this.getProject();
    project.removeDataPoint(this.id);
  }

  removeAll() {
    const ids = [this.id, ...this.getDescendants().map(dp => dp.id)];
    store.dispatch(removeDataPoints(ids));
  }

  getChildren(id) {
    return getDataPoints(store.getState(), {
      parent: id || this.id
    });
  }

  getDescendants(id) {
    let descendants = [];

    this.getChildren(id || this.id).forEach(dp => {
      descendants.push(dp);
      if (dp.id) {
        descendants = descendants.concat(this.getDescendants(dp.id));
      }
    });

    return descendants;
  }

  onChange(event) {
    const value = event._value;
    this.update({
      value
    });
  }
}
