import { DataPoint } from '../DataPoint/class';

export class Component extends DataPoint {
  constructor(props) {
    super(props);
    Object.keys(props).forEach(key => {
      this[key] = props[key];
    });

    this.properties = this.properties || [];
  }

  getDirectItems() {
    const project = this.getProject();
    return (this.items || []).map(id => project.getComponentItem(id));
  }

  getEventHandlers() {
    const project = this.getProject();
    const ids = this.eventHandlerIds || [];
    return ids.map(id => project.getEventHandler(id));
  }
}
