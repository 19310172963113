import { createSlice } from '@reduxjs/toolkit';

const stateSlice = createSlice({
  name: 'state',
  initialState: {},
  reducers: {
    setState: {
      reducer(state, action) {
        const { slice, code, options } = action.payload;
        const fn = new Function(['state', 'payload'], `"use strict"; (() => {${code}})()`);
        fn.apply(null, [state[slice], options]);
      },
      prepare(event, slice, code, options) {
        return { payload: { slice: slice.name, code, options } }
      }
    }
  },
  extraReducers: {
    ['REPLACE_DATA_POINTS']: (state, action) => {
      const { dataPoints } = action.payload;
      const slices = dataPoints.filter(dp => dp.dataPointType === 'slice');

      slices.forEach(slice => {
        if (!state[slice.name]) {
          state[slice.name] = {};
        }

        const sliceProps = dataPoints.filter(dp => dp.dataPointType === 'sliceProp' && dp.sliceId === slice.id);
        const slicePropTypeMap = {
          string: '',
          object: {},
          array: []
        }

        sliceProps.forEach(sliceProp => {
          if (!state[slice.name][sliceProp.name]) {
            state[slice.name][sliceProp.name] = slicePropTypeMap[sliceProp.type];
          }
        });
      });
    }
  }
});

export const { setState } = stateSlice.actions;

export default stateSlice.reducer;

// selectors
export const getState = (store) => store.state;
export const getStateFromSelector = (store, {context, selector, slices, componentItems}) => {
  const sliceKeys = slices.map(slice => slice.name);
  const sliceValues = sliceKeys.map(sliceKey => store.state[sliceKey]);
  const loopContextKeys = selector.loopIds ? selector.loopIds.filter(id => componentItems.find(ci => ci.id === id)).map(id => (componentItems.find(ci => ci.id === id)).getLoopName()) : [];
  const loopContextValues = selector.loopIds ? selector.loopIds.filter(id => componentItems.find(ci => ci.id === id)).map(id => context[(componentItems.find(ci => ci.id === id)).getName()]) : [];
  const fn = new Function([...loopContextKeys, ...sliceKeys], `"use strict"; return (() => { ${selector.code} \n })()`);
  return fn.apply(null, [...loopContextValues, ...sliceValues]);
}
