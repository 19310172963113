import { createSlice } from '@reduxjs/toolkit';

const componentSlice = createSlice({
  name: 'components',
  initialState: {
    byId: {},
    allIds: []
  },
  reducers: {
    replaceComponents: {
      reducer(state, action) {
        const { data } = action.payload;
        state.byId = data.reduce((map, component) => {
          map[component.id] = component;
          return map;
        }, {});
        state.allIds = data.map(component => component.id);
      },
      prepare(data) {
        return { payload: { data } }
      }
    },
    updateComponent: {
      reducer(state, action) {
        const { id, data } = action.payload;
        state.byId[id] = {
          ...state.byId[id],
          ...data
        };
      },
      prepare(id, data) {
        return { payload: { id, data } }
      }
    }
  }
});

export const { replaceComponents, updateComponent } = componentSlice.actions;

export default componentSlice.reducer;

// selectors
export const getComponent = (store, id) => store.components.byId[id];
export const getComponentByName = (store, name) => Object.entries(store.components.byId).map(([id,c]) => c).find(c => c.name === name);
export const getComponentsArray = store => store.components.allIds.map(id => store.components.byId[id]);
