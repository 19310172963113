import { ComponentItem } from '../ComponentItem/class';
import { DataPoint } from '../DataPoint/class';
import elements from '../elements.json';

export class Theme extends DataPoint {

  constructor(props) {
    super(props);

    Object.keys(props).forEach(key => {
      this[key] = props[key];
    });
  }

  update(data) {
    if (data.isModified !== false) {
      data.isModified = true;
    }
    super.update(data);
  }

  getItems(type) {
    const project = this.getProject();
    const projectState = project.getState();
    const itemType = type || projectState.selectedThemeTab;
    const existingStyles = Object.entries(this.getStyles()).reduce((styleArray, [id, data]) => {
      if (!styleArray.find(sa => sa.id === data.elementId)) {
        return styleArray.concat({id: data.elementId, name: data.elementId, className: data.className, elementSelector: data.elementSelector});
      } else {
        return styleArray;
      }
    }, []);

    if (itemType === 'classes') {
      const classes = project.getAllComponentClasses().map(c => ({id: c, name: c, _type: 'className'}));
      const filteredExistingStyles = existingStyles.filter(s => !!s.className && !classes.find(c => c.id === s.id));
      return classes.concat(filteredExistingStyles);
    } else if (itemType === 'elements') {
      // const elements = getElements();
      return elements;
    } else {
      const classesAndElements = project.getAllComponentClasses().map(c => ({id: c, name: c, className: c, _type: 'className'})).concat(elements);
      const filteredExistingStyles = existingStyles.filter(s => !classesAndElements.find(ce => ce.id === s.id));
      return classesAndElements.concat(filteredExistingStyles);
    }
  }

  getActiveItem() {
    const project = this.getProject();
    const projectState = project.getState();
    const selectedThemeItem = projectState.selectedThemeItem;
    return this.getItems().find(item => item.id === selectedThemeItem);
  }

  createComponentFromItem(item = {}) {
    const that = this;
    return new ComponentItem({
      _className: item.className,
      elementId: item.id,
      parentId: this.id,
      styleSelector: item.styleSelector || item.elementSelector,
      id: item.id,
      dataPointType: 'component',
      getProject: that.getProject,
      getCSSSelector() {
        const element = this.getElement();

        return !!item.className ? `.${item.name}` : (this.styleSelector || element.styleSelector || `.${this.getBaseClassName()}`);
      },
      update(data) {
        that.update(data);
      },
      getStyleRules(all) {
        const storedStyles = Object.entries(that.styleRules || {}).filter(([srId, sr]) => all || sr.elementId === item.id);
        return (storedStyles && storedStyles.length ? storedStyles : [[`default-${item.id}`, {
          name: 'Default',
          elementId: item.id
        }]]).reduce((obj, [srId, sr]) => {
          obj[srId] = sr;
          return obj;
        }, {});
      }
      // getStyle() {
      //   return this.getStyle(...arguments);
      // }
    });
  }

  getStyle({ styleRuleId, cssProp }) {
    return (((this.styleRules || {})[styleRuleId] || {}).styles || {})[cssProp];
  }

  getStyles() {
    return this.styleRules || {};
  }

  getComponents() {
    return this.getItems('all').map(item => this.createComponentFromItem(item));
  }

  getCSS() {
    return this.getItems().map(item => this.createComponentFromItem(item)).reduce((css, component) => `${css} ${component.getCSS()}`, '');
  }

}
