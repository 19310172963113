import store from '../redux/store';
import { DataPoint } from '../DataPoint/class';
import { getState, getStateFromSelector } from '../State/slice';

export class Attribute extends DataPoint {

  constructor(props) {
    super(props);

    Object.keys(props).forEach(key => {
      this[key] = props[key];
    });
  }

  getName() {
    const componentProp = this.getComponentProp();
    if (componentProp.id) {
      return componentProp.name;
    } else {
      return this.name;
    }
  }

  getComponentProp() {
    const project = this.getProject();
    return project.getComponentProp(this.componentPropId);
  }

  getComponentItem() {
    const project = this.getProject();
    return project.getComponentItem(this.componentItemId);
  }

  getConnectedState() {
    const project = this.getProject();
    if (this.stateConnectionId) {
      if (this.stateConnectionType === 'selector') {
        return project.getSelector(this.stateConnectionId);
      } else {
        return project.getSliceProp(this.stateConnectionId);
      }
    }
  }

  getValue({loopContext, componentProps}={}) {
    const project = this.getProject();

    if (this.stateConnectionId) {
      if (this.stateConnectionType === 'slice') {
        const sliceProp = project.getSliceProp(this.stateConnectionId);
        const slice = project.getSlice(sliceProp.sliceId);
        const state = getState(store.getState());
        return state[slice.name][sliceProp.name];
      } else if( this.stateConnectionType === 'selector') {
        const selector = project.getSelector(this.stateConnectionId);
        const slices = project.getSlices().filter(slice => (selector.sliceIds || []).includes(slice.id));
        const componentItems = project.getComponentItems();
        if (loopContext) {
          return getStateFromSelector(store.getState(), {context: loopContext || {}, selector, slices, componentItems});
        }
      } else if ( this.stateConnectionType === 'componentProp') {
        const componentProp = project.getComponentProp(this.stateConnectionId);
        if (componentProps) {
          return componentProps[componentProp.name];
        }
      }
    } else {
      return this.value || this.defaultValue;
    }
  }


}
