import React from 'react';

// state management
import { connect } from 'react-redux';

class ComponentOverlay extends React.Component {

  getOverlayStyles() {
    const styles = {};
    const componentNode = this.props.inspectComponent ? document.querySelector(this.props.inspectComponent.getCSSSelector()) : null; //this.componentRef.current;

    if (componentNode) {
      const positions = componentNode.getBoundingClientRect();

      styles.top = positions.top;
      styles.left = positions.left;
      styles.width = positions.width;
      styles.height = positions.height;

      // appearance
      styles.position = 'fixed';
      styles.background = '#bbd6ffcc';
      styles.zIndex = 1000;
      styles.boxSizing = 'border-box';
      styles.pointerEvents = 'none';

      // padding and margin
      const computedStyle = window.getComputedStyle(componentNode);
      styles.borderColor = '#d3ff6d55';
      styles.borderStyle = 'solid';
      styles.borderTopWidth = `${computedStyle.paddingTop}`;
      styles.borderRightWidth = `${computedStyle.paddingRight}`;
      styles.borderBottomWidth = `${computedStyle.paddingBottom}`;
      styles.borderLeftWidth = `${computedStyle.paddingLeft}`;
      const marginColor = '#ffe5bbcc';
      styles.boxShadow = `0 -${computedStyle.marginTop} 0 ${marginColor}, 0 ${computedStyle.marginBottom} 0 ${marginColor}, -${computedStyle.marginLeft} 0 0 ${marginColor}, ${computedStyle.marginRight} 0 0 ${marginColor}`;
    } else {
      styles.display = 'none';
    }

    return styles;
  }

  render() {
    return (
      <div className="component-overlay" style={this.getOverlayStyles()} />
    )
  }
}

const mapStateToProps = (state, props) => {

  const projectState = props.project.getState();
  const inspectComponent = projectState.inspectComponentId ? props.project.getComponentItem(projectState.inspectComponentId) : null;

  return { inspectComponent };
}

export default connect(
  mapStateToProps,
  {  }
)(ComponentOverlay);
