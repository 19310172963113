import { createSlice } from '@reduxjs/toolkit';

const projectStateSlice = createSlice({
  name: 'projectState',
  initialState: {},
  reducers: {
    updateProjectState: {
      reducer(state, action) {
        const { newState } = action.payload;
        Object.assign(state, newState);
      },
      prepare(newState) {
        return { payload: { newState } }
      }
    }
  }
});

export const { updateProjectState } = projectStateSlice.actions;

export default projectStateSlice.reducer;

// selectors
export const getProjectState = store => store.projectState;
