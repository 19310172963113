import React from 'react';

// component
import Component from '../Component';
import ComponentItem from '../ComponentItem';

// state management
import { connect } from 'react-redux';

class ComponentItems extends React.Component {

  getChild(child) {
    const { project, componentProps } = this.props;
    const TagName = child.componentId ? Component : ComponentItem;
    const id = child.componentId || child.id;
    return child.enableLoop && child.loopData ? (
      child.loopData.map((loopContext, index) => (
        <TagName key={`${child.id}-${index}`} project={project} id={id} instanceId={child.id} loopContext={{...this.props.loopContext, [child.name]: loopContext}} componentProps={componentProps} />
      ))
    ) : (
      <TagName key={child.id} id={id} instanceId={child.id} project={project} loopContext={this.props.loopContext} componentProps={componentProps} />
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.props.childDataPoints && JSON.parse(this.props.childDataPoints).map(child => this.getChild(child))}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {

  const { project, id } = props;
  const component = project.getComponentItem(id);
  const childDataPoints = JSON.stringify(component.getChildren().map(child => ({
    id: child.id,
    name: child.getName(),
    enableLoop: child.enableLoop,
    loopData: child.enableLoop ? child.getLoopData() : [],
    componentId: child.componentId
  })));

  // const isSelected = component.isSelected();

  return { childDataPoints };
}

export default connect(
  mapStateToProps,
  {}
)(ComponentItems);
