// import firebase from 'firebase';
import { replaceDataPoints } from '../DataPoint/actions';

export const UPDATE_PROJECT_STATE = 'UPDATE_PROJECT_STATE';
export const updateProjectState = newState => ({
  type: UPDATE_PROJECT_STATE,
  payload: {
    newState
  }
});

export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const updateProject = projectData => ({
  type: UPDATE_PROJECT,
  payload: {
    projectData
  }
});

export const SET_PROJECT_ID = 'SET_PROJECT_ID';
export const setProjectId = projectId => ({
  type: SET_PROJECT_ID,
  payload: {
    projectId
  }
});

export const RESET_DATAPOINT_TRACKING = 'RESET_DATAPOINT_TRACKING';
export const resetDataPointTracking = () => ({
  type: RESET_DATAPOINT_TRACKING
});

// export const REQUEST_PROJECT = 'REQUEST_PROJECT';
// export const requestProject = (workspaceId, projectId) => {
//
//   const db = firebase.firestore();
//
//   return (dispatch) => {
//
//     return db.collection('workspaces').doc(workspaceId).collection('projects').doc(projectId).get().then(doc => {
//       if (doc.exists) {
//         dispatch(updateProject({...doc.data(), id: doc.id}));
//       }
//     }).then(db.collection('workspaces').doc(workspaceId).collection('projects').doc(projectId).collection('datapoints').get().then(snapshot => {
//
//       const dataPoints = snapshot.docs.map(doc => ({...doc.data(), firebaseId: doc.id}));
//       if (dataPoints.length) {
//         dispatch(replaceDataPoints(dataPoints));
//       } else {
//         // empty project => create new data points
//       }
//     })).catch(error => {
//       throw error;
//     });
//   }
// };
