import React from 'react';

//state management
import { connect } from 'react-redux'

// components
import ComponentItem from '../ComponentItem';


class Component extends React.PureComponent {

  constructor(props) {
    super(props);

    this.componentRef = React.createRef();

  }

  componentDidMount() {
    const { eventHandlers, loopContext, componentProps } = this.props;
    const mountEvent = eventHandlers.find(eh => eh.eventId === 'mount');
    if (mountEvent) {
      mountEvent.execute({loopContext, componentProps});
    }
  }

  render() {
    const { project, loopContext, componentItems, componentProps } = this.props;

    return (
      <React.Fragment>
        {componentItems.map(ci => (
          <ComponentItem key={ci.id} id={ci.id} project={project} loopContext={loopContext} componentProps={componentProps} />
        ))}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {

  const { project, id, instanceId, loopContext } = props;

  const component = project.getComponent(id, {loopContext});
  const instance = project.getComponentItem(instanceId);
  const componentProps = instance.getAttributes().reduce((map, a) => {
    map[a.getName()] = a.getValue({loopContext, componentProps: props.componentProps});
    return map;
  }, {});
  const componentItems = component.getDirectItems();
  const eventHandlers = component.getEventHandlers();

  return { componentItems, componentProps, eventHandlers };
}

export default connect(
  mapStateToProps,
  {  }
)(Component);
